import parse from "html-react-parser";
import React from "react";
import * as s from "./ServiceDetails.module.css";

const ServiceDetails = ({ block }) => {

  return (
    <>
      <section className={`${s.block} position-relative`} id={block?.blockId}>
        <div className={`container`}>
          <div className="row">
            <div className="col">
              <h2 className={`${s.title}`}>{parse(block.title)}</h2>
            </div>
          </div>
        </div>
        {block?.items?.length > 0 && <>
          <div className={`container`}>
            <div className={`row ${s.items} justify-content-center`}>
              {block?.items?.map((item, i) => {
                return <div className="col-12 col-md-6 col-xl-4" key={i}>
                  <Item item={item} />
                </div>
              })}
            </div>
          </div>
        </>}
      </section >
    </>
  );
};


const Item = ({ item }) => {
  return <div className={s.item}>
    <div className={s.title}>{parse(item.title)}</div>
    <div className={s.description}>{parse(item.description)}</div>
  </div>
}

export default ServiceDetails;
