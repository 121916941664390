
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { StaticImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import CustomLink from "../CustomLink/CustomLink";
import * as s from "./HeroService.module.css";

const HeroService = ({ block }) => {
  const bp = useBreakpoint();

  return (
    <>
      <section className={`${s.block} position-relative`} id={block?.blockId}>
        {false == bp.md && <>
          <StaticImage
            src="./bg-mobile.png"
            alt="bg"
            placeholder="transparent"
            className="w-100 h-100 position-absolute"
            style={{ top: 0, bottom: 0, left: 0, right: 0 }}
          />
        </>}
        {true == bp.md && <>
          <StaticImage
            src="./bg-desktop.png"
            alt="bg"
            placeholder="transparent"
            className="w-100 h-100 position-absolute"
            style={{ top: 0, bottom: 0, left: 0, right: 0 }}
          />
        </>}
        <div className={`container position-relative`}>
          <div className="row">
            <div className="col-12">
              <div className={s.data}>
                <h1 className={`${s.title}`}>{parse(block.title)}</h1>
                {!!block.description && <div className={`${s.description}`}>{parse(block.description)}</div>}
                <div className={`w-100 d-flex justify-content-center`}>
                  <CustomLink button={block?.button} className={''} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};



export default HeroService;
