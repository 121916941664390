import { graphql } from "gatsby";
import parse from "html-react-parser";
import React from "react";

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
import "../assets/css/@wordpress/block-library/build-style/style.css";
import "../assets/css/@wordpress/block-library/build-style/theme.css";
import schemaBuilder from "../utils/schemaBuilder";
import AddressWithMapBlock from './../components/REDESIGN/AddressWithMapBlock/AddressWithMapBlock';
import AwardsListBlock from './../components/REDESIGN/AwardsListBlock/AwardsListBlock';
import CaseStudiesGridBlock from "./../components/REDESIGN/CaseStudiesGridBlock/CaseStudiesGridBlock";
import ContentWithImageAsideBlock from './../components/REDESIGN/ContentWithImageAsideBlock/ContentWithImageAsideBlock';
import ContentWithImageBlock from './../components/REDESIGN/ContentWithImageBlock/ContentWithImageBlock';
import CtaBannerBlock from './../components/REDESIGN/CtaBannerBlock/CtaBannerBlock';
import FAQBlock from './../components/REDESIGN/FAQBlock/FAQBlock';
import FlippingCardsBlock from "./../components/REDESIGN/FlippingCardsBlock/FlippingCardsBlock";
import GlobalClientsBlock from './../components/REDESIGN/GlobalClientsBlock/GlobalClientsBlock';
import GlobalFormBlock from './../components/REDESIGN/GlobalFormBlock/GlobalFormBlock';
import HeroAltBlock from './../components/REDESIGN/HeroAltBlock/HeroAltBlock';
import HeroService from './../components/REDESIGN/HeroService/HeroService';
import HomeHeroBlock from './../components/REDESIGN/HomeHeroBlock/HomeHeroBlock';
import IconContentGrid from './../components/REDESIGN/IconContentGrid/IconContentGrid';
import IconContentSlider from "./../components/REDESIGN/IconContentSlider/IconContentSlider";
import ImagesSliderBlock from './../components/REDESIGN/ImagesSliderBlock/ImagesSliderBlock';
import LinksGridBlock from './../components/REDESIGN/LinksGridBlock/LinksGridBlock';
import NewsletterSubscribeFooterBannerNew from './../components/REDESIGN/NewsletterSubscribeFooterBannerNew/NewsletterSubscribeFooterBannerNew';
import PostsGridBlock from './../components/REDESIGN/PostsGridBlock/PostsGridBlock';
import ServiceBenefits from './../components/REDESIGN/ServiceBenefits/ServiceBenefits';
import ServiceDetails from './../components/REDESIGN/ServiceDetails/ServiceDetails';
import StatsBlock from './../components/REDESIGN/StatsBlock/StatsBlock';
import StepsBlock from './../components/REDESIGN/StepsBlock/StepsBlock';
import TestimonialsBlock from './../components/REDESIGN/TestimonialsBlock/TestimonialsBlock';
import WorkflowBlock from './../components/REDESIGN/WorkflowBlock/WorkflowBlock';
import Footer from "./../components/REDESIGN/footer/footer";
import Header from './../components/REDESIGN/header/header';
import Seo from "./../components/seo";

const ServiceRedesignedTemplate = ({ data: { services, post, site }, location }) => {

  const schema = schemaBuilder({ service: post, services: services?.edges }, 'service-redesigned', site);

  return (
    <>
      <Header />
      <Seo
        title={post.title}
        description={""}
        pageSeo={post}
        location={location}
        schema={schema}
      />

      <HeroService block={post?.acfServiceRedesigned?.heroBlock} />

      {post?.acfServiceRedesigned?.serviceDetailsGrid && <ServiceDetails block={post?.acfServiceRedesigned?.serviceDetailsGrid} />}
      {post?.acfServiceRedesigned?.benefitsGrid && <ServiceBenefits block={post?.acfServiceRedesigned?.benefitsGrid} />}

      {
        !!post.contentRedesigned.contentRedesigned &&
        post.contentRedesigned.contentRedesigned.map((block, index) => {
          switch (block.__typename) {
            case 'WpService_Contentredesigned_ContentRedesigned_HomeHeroBlock':
              return <HomeHeroBlock block={block} key={`block-${index}`} />
              break;
            case 'WpService_Contentredesigned_ContentRedesigned_HomeAltBlock':
              return <HeroAltBlock block={block} key={`block-${index}`} />
              break;
            case 'WpService_Contentredesigned_ContentRedesigned_IconContentBlock':
              return <IconContentSlider block={block} key={`block-${index}`} />
              break;
            case 'WpService_Contentredesigned_ContentRedesigned_IconContentGridBlock':
              return <IconContentGrid block={block} key={`block-${index}`} />
              break;
            case 'WpService_Contentredesigned_ContentRedesigned_FlippingCardsBlock':
              return <FlippingCardsBlock block={block} key={`block-${index}`} />
              break;
            case 'WpService_Contentredesigned_ContentRedesigned_LinksGrid':
              return <LinksGridBlock block={block} key={`block-${index}`} />
              break;
            case 'WpService_Contentredesigned_ContentRedesigned_StatsBlock':
              return <StatsBlock block={block} key={`block-${index}`} />
              break;
            case 'WpService_Contentredesigned_ContentRedesigned_GlobalClientsBlock':
              return <GlobalClientsBlock block={block} key={`block-${index}`} />
              break;
            case 'WpService_Contentredesigned_ContentRedesigned_CaseStudiesGridBlock':
              return <CaseStudiesGridBlock block={block} key={`block-${index}`} />
              break;
            case 'WpService_Contentredesigned_ContentRedesigned_ContentWithImage':
              return <ContentWithImageBlock block={block} key={`block-${index}`} />
              break;
            case 'WpService_Contentredesigned_ContentRedesigned_ContentWithImageAside':
              return <ContentWithImageAsideBlock block={block} key={`block-${index}`} />
              break;

            case 'WpService_Contentredesigned_ContentRedesigned_WorkflowBlock':
              return <WorkflowBlock block={block} key={`block-${index}`} />
              break;
            case 'WpService_Contentredesigned_ContentRedesigned_TestimonialsBlock':
              return <TestimonialsBlock block={block} key={`block-${index}`} />
              break;
            case 'WpService_Contentredesigned_ContentRedesigned_ImagesSliderBlock':
              return <ImagesSliderBlock block={block} key={`block-${index}`} />
              break;
            case 'WpService_Contentredesigned_ContentRedesigned_PostsGridBlock':
              return <PostsGridBlock block={block} key={`block-${index}`} />
              break;
            case 'WpService_Contentredesigned_ContentRedesigned_FaqBlocl':
              return <FAQBlock block={block} key={`block-${index}`} />
              break;
            case 'WpService_Contentredesigned_ContentRedesigned_GlobalFormBlock':
              return <GlobalFormBlock block={block} key={`block-${index}`} />
              break;
            case 'WpService_Contentredesigned_ContentRedesigned_StepsBlock':
              return <StepsBlock block={block} key={`block-${index}`} />
              break;
            case 'WpService_Contentredesigned_ContentRedesigned_CtaBannerBlock':
              return <CtaBannerBlock block={block} key={`block-${index}`} pageTitle={page.title} />
              break;
            case 'WpService_Contentredesigned_ContentRedesigned_AddressWithMapBlock':
              return <AddressWithMapBlock block={block} key={`block-${index}`} pageTitle={page.title} />
              break;
            case 'WpService_Contentredesigned_ContentRedesigned_AwardsListBlock':
              return <AwardsListBlock block={block} key={`block-${index}`} pageTitle={page.title} />
              break;

            default:
              return <section className="container d-none" style={{ 'wordBreak': 'break-word' }} key={`block-${index}`}>
                {block.__typename}
                {/* {JSON.stringify(block, null ,2)} */}
                <hr />
              </section>
          }
        })
      }
      <section className="container">
        {!!post.content && (
          <section>{parse(post.content)}</section>
        )}
      </section>
      <NewsletterSubscribeFooterBannerNew pageTitle={'service' - post.title} />
      <Footer />
    </>
  )
}

export default ServiceRedesignedTemplate

export const pageQuery = graphql`
  query ServiceRedesignedById(
    $id: String!
  ) {
    site {
      siteMetadata {
        siteUrl: url
        title
      }
    }
    services: allWpService {
      edges {
        service: node {
          title
          uri
        }
      }
    }
    post: wpService(id: { eq: $id }) {
      id
      excerpt
      content
      title
      uri
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100)
            }
          }
        }
      }
      seo {
        metaKeywords
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        focuskw
        opengraphImage {
          localFile {
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        twitterImage {
          localFile {
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        opengraphType
      }
      acfServiceRedesigned {
        schemaBlock {
          showPrices
          minPrice
          maxPrice
          showRating
          ratingCount
          ratingValue
        }
        heroBlock {
          title
          description
          button {
            url
            title
            target
          }
        }
        serviceDetailsGrid {
          title
          items {
            title
            description
          }
        }
        benefitsGrid {
          title
          description
          items {
            icon {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
                publicURL
              }
            }
            title
            description
          }
        }
      }
      contentRedesigned {
        contentRedesigned {
          ... on WpService_Contentredesigned_ContentRedesigned_HomeHeroBlock {
            __typename
            blockId
            fieldGroupName
            title
            description
            button {
              url
              title
              target
            }
            backgroundImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
            images {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
              altText
            }
          }
          ... on WpService_Contentredesigned_ContentRedesigned_HomeAltBlock {
            __typename
            blockId
            title
            description
            button {
              url
              title
              target
            }
            rightImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
            bottomContent
          }
          ... on WpService_Contentredesigned_ContentRedesigned_IconContentBlock {
            __typename
            blockId
            title
            description
            items {
              title
              description
              icon {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100)
                  }
                  publicURL
                }
              }
            }
          }
          ... on WpService_Contentredesigned_ContentRedesigned_IconContentGridBlock {
            __typename
            blockId
            title
            description
            items {
              title
              description
              icon {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100)
                  }
                  publicURL
                }
              }
            }
          }
          ... on WpService_Contentredesigned_ContentRedesigned_FlippingCardsBlock {
            __typename
            blockId
            title
            items {
              problem {
                title
                description
              }
              solution {
                title
                description
              }
            }
          }
          ... on WpService_Contentredesigned_ContentRedesigned_LinksGrid {
            __typename
            blockId
            title
            description
            itemsAlignment
            linkGroups {
              title
              links {
                link {
                  url
                  title
                  target
                }
                description
              }
            }
          }
          ... on WpService_Contentredesigned_ContentRedesigned_StatsBlock {
            __typename
            blockId
            layout
            title
            description
            items {
              value
              title
              description
            }
          }
          ... on WpService_Contentredesigned_ContentRedesigned_GlobalClientsBlock {
            __typename
            blockId
            fieldGroupName
            title
            button {
              url
              title
              target
            }
            useLocal
            clients {
              client {
                mediaItemUrl
                localFile {
                  childImageSharp {
                      gatsbyImageData(quality: 100)
                  }
                }
                altText
              }
            }
          }
          ... on WpService_Contentredesigned_ContentRedesigned_CaseStudiesGridBlock {
            __typename
            blockId
            title
            items {
              item {
                ... on WpCaseStudy {
                  id
                  title
                  uri
                  featuredImage {
                    node {
                      altText
                      localFile {
                        childImageSharp {
                          gatsbyImageData(quality: 100)
                        }
                      }
                    }
                  }
                  acfCaseStudy {
                    mainData {
                      logo {
                        altText
                        localFile {
                          childImageSharp {
                            gatsbyImageData(quality: 100)
                          }
                        }
                      }
                      links {
                        link {
                          url
                          title
                          target
                        }
                      }
                    }
                  }
                  terms {
                    nodes {
                      ... on WpCaseStudyTag {
                        id
                        name
                        uri
                      }
                    }
                  }
                }
              }
            }
            button {
              url
              title
              target
            }
          }
          ... on WpService_Contentredesigned_ContentRedesigned_ContentWithImage {
            __typename
            blockId
            title
            content
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
                publicURL
              }
            }
          }
          ... on WpService_Contentredesigned_ContentRedesigned_ContentWithImageAside {
            __typename
            blockId
            title
            subtitle
            imagePosition
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
                publicURL
              }
            }
            content
          }
          ... on WpService_Contentredesigned_ContentRedesigned_WorkflowBlock {
            __typename
            blockId
            title
            items {
              color
              title
              description
            }
          }
          ... on WpService_Contentredesigned_ContentRedesigned_TestimonialsBlock {
            __typename
            blockId
            title
            testimonials {
              testimonial {
                ... on WpTestimonial {
                  acf_testimonial {
                    position
                    stars
                    companyLogo {
                      altText
                      localFile {
                        childImageSharp {
                          gatsbyImageData(quality: 100)
                        }
                        publicURL
                      }
                    }
                  }
                  content
                  title
                  featuredImage {
                    node {
                      __typename
                      ... on WpMediaItem {
                        localFile {
                          childImageSharp {
                            gatsbyImageData(quality: 100)
                          }
                        }
                        altText
                      }
                    }
                  }
                }
              }
            }
          }
          ... on WpService_Contentredesigned_ContentRedesigned_ImagesSliderBlock {
            __typename
            blockId
            title
            description
            images {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
              altText
            }
          }
          ... on WpService_Contentredesigned_ContentRedesigned_PostsGridBlock {
            __typename
            blockId
            title
            showCustomPosts
            posts {
              post {
                ... on WpPost {
                  uri
                  title
                  featuredImage {
                    node {
                      altText
                      localFile {
                        childImageSharp {
                          gatsbyImageData(quality: 100)
                        }
                      }
                    }
                  }
                }
              }
            }
            button {
              url
              title
              target
            }
          }
          ... on WpService_Contentredesigned_ContentRedesigned_FaqBlocl {
            __typename
            blockId
            title
            items {
              question
              answer
            }
          }
          ... on WpService_Contentredesigned_ContentRedesigned_GlobalFormBlock {
            __typename
            blockId
            title
            description
          }
          ... on WpService_Contentredesigned_ContentRedesigned_StepsBlock {
            __typename
            blockId
            title
            description
            steps {
              title
              description
            }
          }
          ... on WpService_Contentredesigned_ContentRedesigned_CtaBannerBlock {
            __typename
            blockId
            title
            description
            button {
              url
              title
              target
            }
          }
          ... on WpService_Contentredesigned_ContentRedesigned_AddressWithMapBlock {
            __typename
            blockId
            mapType
            mapIframe
            mapImage {
              altText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
            mapImageUrl
            icon {
              altText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
            address
          }
          ... on WpService_Contentredesigned_ContentRedesigned_AwardsListBlock {
            __typename
            blockId
            title
            awards {
              image {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(quality: 100)
                  }
                }
              }
              title
              description
            }
          }
        }
      }
      acf_services {
        schemaBlock {
          showPrices
          minPrice
          maxPrice
          showRating
          ratingValue
          ratingCount
        }
        bannerBlock {
          title
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
          }
          buttonPage {
            ... on WpPage {
              uri
              title
            }
          }
          buttonText
        }
        cardBlock {
          color
          description
          fieldGroupName
          title
          logo {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
          }
          hoverLogo {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
          }
        }
        faqBlock {
          question
          answer
        }
        formBlock {
          title
          subtitle
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
          }
        }
        offersBlock {
          afterTitle
          beforeTitle
          fieldGroupName
          title
          items {
            content
            title
            icon {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
          }
        }
        offersBlock2 {
          afterTitle
          beforeTitle
          fieldGroupName
          title
          items {
            content
            title
            icon {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
          }
        }
        relatedServices {
          title
          subtitle
          services {
            fieldGroupName
            service {
              __typename
              ... on WpService {
                databaseId
                uri
                title
                excerpt
                acf_services {
                  cardBlock {
                    color
                    description
                    title
                    logo {
                      mediaItemUrl
                      localFile {
                        childImageSharp {
                          gatsbyImageData(quality: 100)
                        }
                      }
                      altText
                    }
                    hoverLogo {
                      mediaItemUrl
                      localFile {
                        childImageSharp {
                          gatsbyImageData(quality: 100)
                        }
                      }
                      altText
                    }
                  }
                }
                featuredImage {
                  node {
                    __typename
                    ... on WpMediaItem {
                      mediaItemUrl
                      localFile {
                        childImageSharp {
                          gatsbyImageData(quality: 100)
                        }
                      }
                      altText
                    }
                  }
                }
              }
            }
          }
        }
        dataItems {
          title
          subtitle
          itemsBackgroundColor
          itemsTitleColor
          items {
            title
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
          }
        }
        relatedPostsBlock {
          title
          posts {
            post {
              ... on WpPost {
                id
                uri
                title
                featuredImage {
                  node {
                    gatsbyImage(width: 600,quality: 80)
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(quality: 100)
                      }
                    }
                  }
                }
              }
            }
          }
        }
        teamBlock {
          title
          images {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
            altText
          }
        }
        testimonialsBlock {
          title
          testimonials {
            testimonial {
              __typename
              ... on WpTestimonial {
                acf_testimonial {
                  position
                  stars
                }
                content
                title
                featuredImage {
                  node {
                    __typename
                    ... on WpMediaItem {
                      mediaItemUrl
                      localFile {
                        childImageSharp {
                          gatsbyImageData(quality: 100)
                        }
                      }
                      altText
                    }
                  }
                }
              }
            }
          }
        }
        clientsBlock {
          title
          useLocal
          clients {
            client {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                  original {
                    height
                    width
                  }
                }
              }
            }
          }
        }
        clientsCtaButton{
          layout
          link {
            target
            title
            url
          }
          borderRadius
          backgroundColor
          icon {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
          }
        }
        achievements {
          useLocal
          title
          subtitle
          description
          items {
            icon {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
            title
            content
          }
        }
        textImagesBlock {
          title
          items {
            content
            title
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
          }
        }

        textImagesCtaButton {
          layout
          link {
            target
            title
            url
          }
          borderRadius
          backgroundColor
          icon {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
          }
        }

        topBlock {
          title
          subtitle
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
          }
          buttonPage {
            ... on WpPage {
              uri
              title
            }
          }
          buttonText
          textColor
          useGradient
          backgroundColor
          gradient
        }
        videoBlock {
          youtubeVideoId
          title
          afterTitle
        }
        workflowBlock {
          title
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
          }
          items {
            content
            title
          }
        }
      }
    }
  }
`
