import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import * as s from "./ServiceBenefits.module.css";

const ServiceBenefits = ({ block }) => {
  const bp = useBreakpoint();


  return (
    <>
      <section className={`${s.block} position-relative`} id={block?.blockId}>
        <div className={`container`}>
          <div className="row">
            <div className="col">
              <h2 className={`${s.title}`}>{parse(block.title)}</h2>
              {!!block.description && <div className={`${s.description}`}>{parse(block.description)}</div>}
            </div>
          </div>
        </div>
        {block?.items?.length > 0 && <>
          <div className={`container`}>
            <div className={`row ${s.items} justify-content-center`}>
              {block?.items?.map((item, i) => {
                return <div className="col-12 col-md-6 col-xl-4 d-flex flex-column" key={i} style={{ gap: '32px' }}>
                  <Item item={item} />
                </div>
              })}
            </div>
          </div>
        </>}
      </section >
    </>
  );
};


const Item = ({ item }) => {
  const icon = {
    data: item?.icon?.localFile?.childImageSharp?.gatsbyImageData,
    alt: item?.icon?.altText || ``,
    src: item?.icon?.localFile?.publicURL,
  };
  return <div className={s.item}>
    {!!icon?.data ?
      <GatsbyImage
        className={s.icon}
        loading="eager"
        placeholder="transparent"
        image={icon.data}
        alt={icon.alt} />
      : <img src={icon.src} alt={icon.alt} loading="lazy" className={s.icon} />}
    <div>
      <div className={s.title}>{parse(item.title)}</div>
      <div className={s.description}>{parse(item.description)}</div>
    </div>
  </div>
}

export default ServiceBenefits;
